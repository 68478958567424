import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useViewportData } from '../../../hooks'
import { CampaignPerformance, ModuleProps } from '../../../types'
import LoadingBoxes from '../../../../entries/LoadingBoxes'
import TotalRow from './TotalRow'

const CampaignColumn: React.FC<{
  field: string
  data: CampaignPerformance
  reverseColor?: boolean
}> = ({ field, data, reverseColor = false }) => {
  function getColor(value: string, reverse: boolean = false): string {
    if (value?.toString()?.includes('-')) {
      return reverse ? 'text-success' : 'text-danger'
    } else {
      return reverse ? 'text-danger' : 'text-success'
    }
  }

  const differenceField = `${field}_difference`
  const percentageField = `${field}_diff_percentage`

  if (field === 'campaign_name') {
    // Check if the campaign_name is a URL
    const isURL = (str: string) => /^(https?:\/\/)/.test(str)
    const campaignName = data[field]

    // If it's a valid URL, show the image
    if (isURL(campaignName)) {
      return <img src={campaignName} alt="Campaign" style={{ maxWidth: '100px' }} />
    }

    // If it's not a URL, show the name as text
    return <span>{campaignName || 'No Image Available'}</span>
  }
  return (
    <>
      <span>{data[field]}</span>
      <br />
      <small
        className={getColor(data[differenceField], reverseColor)}
        title={data[percentageField]}
        onMouseEnter={(e) => (e.currentTarget.innerText = data[percentageField])}
        onMouseLeave={(e) => (e.currentTarget.innerText = data[differenceField])}
      >
        {data[differenceField]}
      </small>
    </>
  )
}

const PerformanceByCampaign: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  const campaigns = data?.[item?.module]
  const totalRow = campaigns?.[campaigns.length - 1] // Assume the last row is the total row
  const campaignsWithoutTotal = campaigns?.slice(0, campaigns.length - 1) // Exclude the last row for sorting

  // Define columns dynamically
  const columns = [
    {
      field: 'campaign_name',
      header: 'Campaign Name',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="campaign_name" data={rowData} />
      ),
    },
    {
      field: 'clicks',
      header: 'Clicks',
      body: (rowData: CampaignPerformance) => <CampaignColumn field="clicks" data={rowData} />,
    },
    {
      field: 'leads',
      header: 'Leads',
      body: (rowData: CampaignPerformance) => <CampaignColumn field="leads" data={rowData} />,
    },
    {
      field: 'cost_per_lead',
      header: 'Cost Per Lead',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="cost_per_lead" data={rowData} reverseColor />
      ),
    },
    {
      field: 'cost',
      header: 'Amount Spent',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="cost" data={rowData} reverseColor />
      ),
    },
  ]

  return (
    <div ref={viewportRef}>
      {!loading && data && campaigns ? (
        <div>
          <DataTable
            value={campaignsWithoutTotal}
            className="table table-sm table-striped mt-3"
            rows={10}
            scrollable
            scrollHeight="400px" // Adjust this value based on your design
            sortMode="multiple"
          >
            {columns.map((col, idx) => (
              <Column key={idx} field={col.field} header={col.header} body={col.body} sortable />
            ))}
          </DataTable>

          {/* Render the total row using the TotalRow component */}
          {totalRow && <TotalRow totalRow={totalRow} columns={columns} />}
        </div>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default PerformanceByCampaign
