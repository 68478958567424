import React from 'react'
import { formatTotal } from '../utils'
import { ModuleComponentConfig, ModuleItem } from '../../../types'

const allowedModulesForTotal = [
  'Google Adwords Ads',
  'Google Display Ads',
  'Google VLA Ads',
  'Google Ads',
  'Facebook Ads',
  'Google Analytics',
]

export const TotalForTimePeriod: React.FC<{
  item: ModuleItem
  moduleConfig: ModuleComponentConfig
  totalForTimePeriod: string | number | null
  editingPreviewTotal: { [key: string]: string | number } | null
}> = ({ item, moduleConfig, totalForTimePeriod, editingPreviewTotal }) => {
  if (!allowedModulesForTotal.includes(moduleConfig?.group)) return null
  return (
    <>
      <h3 className="text-center mt-2">
        {moduleConfig?.group === 'Facebook Ads'
          ? formatTotal(
              editingPreviewTotal?.[item?.module] || totalForTimePeriod,
              moduleConfig?.isCurrency,
              moduleConfig?.isPercentage
            )
          : editingPreviewTotal?.[item?.module] || totalForTimePeriod}
      </h3>
    </>
  )
}
