import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react'
import { Dealership, DashboardLevel, Manufacturer, User, Website, AnalyticsBlock } from '../types'
import { CurrentUserContext, WebsiteContext } from '../../contexts'
import { useFetchDealership, useFetchManufacturer, useFetchWebsite } from '../../dataHooks'
import { useParams } from 'react-router-dom'
import { fetchAnalyticsBlock } from '../api'

type AnalyticsDashboardContextType = {
  analyticsBlock: AnalyticsBlock
  analyticsBlockLoading: boolean
  canView: boolean
  dashboardLevel: DashboardLevel | undefined
  dashboardLevelLoaded: boolean
  editModeEnabled: boolean
  toggleEditMode: () => void
  currentUser: User
  dealership: Dealership
  website: Website
  manufacturer: Manufacturer
}

export const AnalyticsDashboardContext = createContext<AnalyticsDashboardContextType>({
  editModeEnabled: false,
  toggleEditMode: () => {},
  canView: false,
  dashboardLevel: undefined,
  dashboardLevelLoaded: false,
  analyticsBlockLoading: false,
  analyticsBlock: {
    data: {
      tabs: [],
      config: {},
    },
    id: 0,
    created_at: '',
    updated_at: '',
    created_by: 0,
    dealership_id: 0,
  },
  currentUser: {
    id: 0,
    email: '',
    admin: false,
    name: '',
    position: '',
    slug: '',
    avatar_url: '',
    dealerships: {},
  },
  dealership: {
    id: '',
    name: '',
    slug: '',
  },
  website: {
    id: '',
    name: '',
    slug: '',
  },
  manufacturer: {
    id: '',
    name: '',
    slug: '',
  },
})

export const AnalyticsDashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)
  const [canView, setCanView] = useState<boolean>(false)
  const currentUser = useContext(CurrentUserContext) as User

  const { dealership, loading: dealershipLoading } = useFetchDealership() as {
    dealership: Dealership
    loading: boolean
  }
  const { website, loading: websiteLoading } = useFetchWebsite() as {
    website: Website
    loading: boolean
  }

  const manufacturer = useFetchManufacturer()
  const [analyticsBlock, setAnalyticsBlock] = useState<AnalyticsBlock | null>(null)
  const [analyticsBlockLoading, setAnalyticsBlockLoading] = useState<boolean>(true)

  const { dealershipSlug, websiteSlug, manufacturerSlug } = useParams()

  let dashboardLevel: DashboardLevel | undefined = undefined

  if (manufacturerSlug) {
    dashboardLevel = 'Manufacturer'
  } else if (websiteSlug) {
    dashboardLevel = 'Website'
  } else if (dealershipSlug && !websiteSlug) {
    dashboardLevel = 'Dealership'
  }

  // Will fully impelement this later, for now just dealership
  const dashboardLevelLoaded: boolean =
    (dashboardLevel === 'Dealership' && !!dealership && !dealershipLoading) ||
    (dashboardLevel === 'Website' && !!website && !websiteLoading) ||
    (dashboardLevel === 'Manufacturer' && !!manufacturer)

  const toggleEditMode = (): void => {
    setEditModeEnabled(!editModeEnabled)
  }

  // This first checks if the dashboard level currentUser is loaded.
  // Then it loops through the dealerships the currentUser has access to,
  // and checks if any of the dealership slugs match the current dealership slug.
  // If the user is a manager or an admin, they can view the dashboard.
  // If not, they cannot view the dashboard.
  useEffect(() => {
    if (dashboardLevelLoaded && currentUser) {
      if (currentUser.admin) {
        setCanView(true)
        return
      }
      for (const dealership in currentUser.dealerships) {
        if (currentUser.dealerships.hasOwnProperty(dealership)) {
          if (dealershipSlug === currentUser.dealerships[dealership].slug) {
            if (currentUser.dealerships[dealership].manager) {
              setCanView(true)
              return
            }
          }
        }
      }
      setCanView(false)
    }
  }, [dashboardLevelLoaded, currentUser, dealershipSlug])

  useEffect(() => {
    const fetchAnalyticsBlockData = async () => {
      setAnalyticsBlockLoading(true)
      try {
        // TODO: Temporary
        if (dashboardLevel === 'Website' || dashboardLevel === 'Manufacturer') {
          const rootElement = document.getElementById('app_root')
          const analyticsBlockFromDOM = JSON.parse(rootElement?.getAttribute('analytics_block'))
          setAnalyticsBlock(analyticsBlockFromDOM)
        } else {
          const response = await fetchAnalyticsBlock(
            dashboardLevel,
            dashboardLevelLoaded,
            dealership,
            manufacturer,
            website
          )
          setAnalyticsBlock(response)
        }
      } catch (error) {
        console.error('Error fetching analytics block:', error)
      } finally {
        setAnalyticsBlockLoading(false)
      }
    }
    fetchAnalyticsBlockData()
  }, [dashboardLevel, dashboardLevelLoaded, dealership, manufacturer, website])

  return (
    <AnalyticsDashboardContext.Provider
      value={{
        analyticsBlock,
        analyticsBlockLoading,
        canView,
        dashboardLevel,
        dashboardLevelLoaded,
        editModeEnabled,
        toggleEditMode,
        currentUser,
        dealership,
        website,
        manufacturer,
      }}
    >
      {children}
    </AnalyticsDashboardContext.Provider>
  )
}
