export const maxModules = 15

export const rootUrl = document.location.origin

export const salesCategories = [
  'New Vehicle Enquiry',
  'Used Vehicle Enquiry',
  'Test Drive Booking',
  'Special Offer Enquiry',
  'Fleet Enquiry',
  'Finance Enquiry',
  'Insurance Enquiry',
  'Subscription Enquiry',
  'Team Member Enquiry',
  'Vehicle Reservation',
]

export const ga4Modules: string[] = [
  'google_analytics',
  'sessions',
  'sessions_by_medium',
  'total_users',
  'traffic_by_device',
  'new_users',
  'views',
  'top_page_views',
]

export const facebookAdsModules: string[] = [
  'facebook_ads_inline_link_clicks',
  'facebook_ads_impressions',
  'facebook_ads_reach',
  'facebook_ads_cpc',
  'facebook_ads_leads',
  'facebook_ads_spend',
  'facebook_ads_ctr',
  'facebook_ads_cost_per_lead',
  'facebook_ads_engagement_by_campaign',
]

export const overrideExcludedModules = [
  'facebook_ads_engagement_by_campaign',
  'google_ad_performance_by_campaign',
  'google_ads_performance_by_campaign',
  'google_vla_ads_performance_by_campaign',
  'google_display_ads_performance_by_campaign',
  'google_ads_top_performing_keywords',
  'top_page_views',
]

export const isFacebookAdsModule = (module: string) => facebookAdsModules.includes(module)
