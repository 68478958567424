import { COLOURS } from '../../../entries/utils'
import type {
  BarChartOptionsConfig,
  ChartData,
  ChartDataConfig,
  ChartDataConfigDataset,
  ChartDataItem,
  GenerateChartDataConfigParams,
  GenerateMultiChartDataConfigParams,
  LineChartOptionsConfig,
  PieChartOptionsConfig,
} from './types'
import { ChartType, ModuleComponentConfig } from '../../types'
import { ChartDataset } from 'chart.js'
import { mergeDeep } from '../../utils'

export const generateChartDataConfig = ({
  chartData,
  chartType = 'Bar',
  maxItems = chartType === 'Pie' ? 10 : chartData?.length,
}: GenerateChartDataConfigParams): ChartDataConfig => {
  if (!chartData || !Array.isArray(chartData)) {
    return {
      labels: [],
      datasets: [{ data: [], backgroundColor: '' }],
    }
  }

  const enquiryRemovedChartData = chartData.map((item: ChartDataItem) => {
    if (typeof item[0] === 'string' && item[0].includes('Enquiry')) {
      item[0] = item[0].replace(' Enquiry', '').trim()
    }
    return item
  })

  const trimmedChartData =
    chartType === 'Pie'
      ? enquiryRemovedChartData.filter((item: ChartDataItem) => item[1] > 0)
      : enquiryRemovedChartData

  const limitedChartData = trimmedChartData?.slice(0, maxItems)

  return {
    labels: limitedChartData?.map((item: ChartDataItem) => item[0]),
    datasets: [
      {
        data: limitedChartData?.map((item: ChartDataItem) => item[1]),
        backgroundColor: chartType !== 'Pie' ? COLOURS.primary : chartColors,
        borderColor: chartType !== 'Pie' ? chartColors[0] : undefined,
      },
    ],
  }
}

export const generateChartOptionsConfig = ({
  chartType,
  moduleComponent,
  chartData,
  chartDataConfig,
  isOverlay,
  timeRangeType,
}: {
  chartType: ChartType
  moduleComponent: ModuleComponentConfig
  chartData: ChartDataItem
  chartDataConfig: ChartDataConfig
  isOverlay?: boolean
  timeRangeType?: string
}) => {
  const multiDataset = chartDataConfig.datasets.length > 1

  switch (chartType) {
    case 'Bar':
      const defaultBarChartOptions: BarChartOptionsConfig = {
        maintainAspectRatio: false,
        plugins: {
          legend: chartDataConfig?.datasets?.some(
            (dataset: ChartDataConfigDataset) => dataset.label
          )
            ? Object.keys(chartData).length < 13
              ? true
              : {
                  labels: {
                    boxWidth: 10,
                    padding: 5,
                    font: {
                      size: 11,
                    },
                  },
                }
            : false,
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                const index = tooltipItem[0]?.dataIndex
                const label1 = tooltipItem[0]?.label
                // For when there are nested datasets
                const label2 = tooltipItem[0]?.dataset?.label

                if (moduleComponent?.isConversionRateModule) {
                  // BE is returning a special tooltip for conversion rate modules, which sits at index 2 of each item in chartData
                  return (chartData?.[index]?.[2] ||
                    chartData?.[label2].find(
                      (firstLabel: string) => firstLabel[0] === label1
                    )[2]) as string
                }
                return `${chartDataConfig?.labels?.[index]}` || ''
              },
              label: (tooltipItem) => {
                return (
                  (moduleComponent?.isCurrency ? '$' : '') +
                  tooltipItem.raw +
                  (moduleComponent?.isPercentage ? '%' : '')
                )
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text:
                moduleComponent?.xLabel === 'Day/Month'
                  ? timeRangeType === 'yearly'
                    ? 'Month'
                    : 'Day'
                  : moduleComponent?.xLabel,
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: moduleComponent?.yLabel,
            },
            ticks: {
              callback: (value) => {
                return (
                  (moduleComponent?.isCurrency ? '$' : '') +
                  (Number.isInteger(value) ? value : value.toFixed(2)) +
                  (moduleComponent?.isPercentage ? '%' : '')
                )
              },
            },
          },
        },
        animation: isOverlay ? false : true,
      }
      return mergeDeep(defaultBarChartOptions, moduleComponent?.barChartOptionsConfig)

    case 'Pie':
      const defaultPieChartOptions: PieChartOptionsConfig = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              generateLabels: (chart) => {
                const datasets = chart.data.datasets as ChartDataset<'pie'>[]
                return datasets[0].data.map((data, i) => ({
                  text: `${chart?.data?.labels[i]} (${data})`,
                  fillStyle: datasets[0].backgroundColor[i] as string,
                  lineWidth: 0,
                  // pointerStyle: 'circle'
                }))
              },
            },
          },
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                const index = tooltipItem[0].dataIndex
                const label = tooltipItem[0]?.dataset?.label
                if (moduleComponent?.isConversionRateModule) {
                  // BE is returning a special tooltip for conversion rate modules, which sits at index 2 of each item in chartData
                  return chartData[index][2] || chartData?.[label]?.[index]?.[2]
                }
              },
            },
          },
        },
        animation: isOverlay ? false : true,
      }
      return mergeDeep(defaultPieChartOptions, moduleComponent?.pieChartOptionsConfig)

    case 'Line':
      const defaultLineChartOptions: LineChartOptionsConfig = {
        maintainAspectRatio: false,
        plugins: {
          legend: multiDataset ? true : false,
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                return (
                  (moduleComponent?.isCurrency ? '$' : '') +
                  tooltipItem.raw +
                  (moduleComponent?.isPercentage ? '%' : '')
                )
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text:
                moduleComponent?.xLabel === 'Day/Month'
                  ? timeRangeType === 'yearly'
                    ? 'Month'
                    : 'Day'
                  : moduleComponent?.xLabel,
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: moduleComponent?.yLabel,
            },
            ticks: {
              callback: (value) => {
                return (
                  (moduleComponent?.isCurrency ? '$' : '') +
                  (Number.isInteger(value) ? value : value.toFixed(2)) +
                  (moduleComponent?.isPercentage ? '%' : '')
                )
              },
            },
          },
        },
        animation: isOverlay ? false : true,
      }
      return mergeDeep(defaultLineChartOptions, moduleComponent?.lineChartOptionsConfig)
  }
}

export const generateMultiChartDataConfig = ({
  chartData,
  chartType,
}: GenerateMultiChartDataConfigParams): ChartDataConfig => {
  if (!chartData || Object.keys(chartData).length === 0)
    return {
      labels: [],
      datasets: [{ data: [], backgroundColor: '' }],
    }

  // Remove empty datasets
  const filteredChartData: ChartData = {}
  Object.entries(chartData).forEach(([key, value]) => {
    if (value.length > 0) {
      filteredChartData[key] = value
    }
  })

  // Get all labels from the first dataset as a reference
  const allLabels = Array.from(
    new Set(Object.values(filteredChartData).flatMap((data) => data.map((entry) => entry[0])))
  )

  const chartConfig = {
    labels: allLabels,
    tooltips: allLabels.map((label) => (label.length > 2 ? label[2] : label[0])),
    datasets: Object.keys(filteredChartData).map((websiteName, index) => {
      // Create data array aligned with allLabels
      const data = allLabels.map((label) => {
        const entry = filteredChartData[websiteName].find(([entryLabel]) => entryLabel === label)
        return entry ? entry[1] : 0 // Default to 0 if no matching entry
      })

      return {
        label: websiteName,
        data: data,
        backgroundColor: chartType === 'Pie' ? chartColors : chartColors[index],
        borderColor: chartType === 'Pie' ? undefined : chartColors[index],
      }
    }),
  }

  return chartConfig
}

export const hasMultipleDataSets = (chartData: ChartData): boolean | undefined => {
  // If there's one dataset it will be an array:
  if (Array.isArray(chartData)) {
    return false
  }

  // If there's multiple datasets it will be an object:
  if (typeof chartData === 'object' && chartData !== null) {
    // Check that each of the object values is an array
    const values = Object.values(chartData)
    if (values.length > 0) {
      return values.every((value) => Array.isArray(value))
    }
  }

  // return undefined for unknown data
  return undefined
}

export const chartColors = [
  '#2085ec', // Blue
  '#72b4eb', // Light Blue
  '#0a417a', // Dark Blue
  '#8464a0', // Purple
  '#cea9bc', // Light Pink
  '#f48fb1', // Pink
  '#ffb74d', // Orange
  '#4db6ac', // Teal
  '#81c784', // Green
  '#ff8a65', // Coral
  '#d1b500', // Dull Gold
  '#e65100', // Dull Deep Orange
  '#7cb342', // Dull Light Green
  '#9e9d24', // Dull Lime
  '#fbc02d', // Dull Yellow
  '#455a64', // Dull Blue Grey
  '#c2185b', // Dull Magenta
  '#7b1fa2', // Dull Deep Purple
  '#0097a7', // Dull Cyan
  '#00796b', // Dull Dark Teal
  '#4e3b31', // Dull Brown
  '#ec407a', // Dull Light Magenta
  '#9c6b9f', // Dull Lavender
  '#7e6b5c', // Dull Taupe
  '#a7c6c2', // Dull Light Aqua
  '#ffa000', // Dull Amber
]

export function formatTotal(
  value: string | number,
  currency: boolean,
  percentage: boolean
): string {
  // If value is not a finite number, return an empty string
  if (typeof value !== 'number' || !isFinite(value) || isNaN(value)) {
    return ''
  }

  // Determine the number of decimal places based on whether the value is an integer
  const isInteger = Number.isInteger(value)
  const minimumFractionDigits = isInteger ? 0 : 2
  const maximumFractionDigits = 2

  // Format the value with commas as thousands separators
  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)

  if (currency) {
    return '$' + formattedValue
  } else if (percentage) {
    return formattedValue + '%'
  } else {
    return formattedValue
  }
}
