import { Dialog } from 'primereact/dialog'
import { useEffect, useRef, useState } from 'react'
import { Campaign, ChartType, ModuleItem } from '../../../types'
import { getModuleComponentFromName } from '../../../utils'
import { moduleComponents } from '../../../config'
import { fetchModuleData } from '../../../api'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { salesCategories } from '../../../constants'
import Select, { components } from 'react-select'
import * as Routes from '../../../../../routes'
import FilterCampaigns from './FilterCampaigns'
import { Switch } from '../../../../entries/FormElements'
import {
  ExcludedCategoriesDisclaimer,
  InsufficientDataSourcesDisclaimer,
  SpamLeadsDisclaimer,
} from './Disclaimers'
import {
  useAnalyticsDashboard,
  useItems,
  usePreviewData,
  useTabs,
  useTimeRangeLocation,
} from '../../../contexts/hooks'

const EditModule: React.FC<{ item: ModuleItem; chartType: ChartType }> = ({ item, chartType }) => {
  const { timeRange } = useTimeRangeLocation()
  const { selectedTab, tabLayouts, setTabLayouts } = useTabs()
  const { handleSetChartTypes } = useItems()
  const { dashboardLevel, dashboardLevelLoaded, dealership, website, manufacturer } =
    useAnalyticsDashboard()
  const { setEditingPreviewData } = usePreviewData()

  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const [allCategories, setAllCategories] = useState<string[]>([])
  const [includedCategories, setIncludedCategories] = useState<string[]>(salesCategories)
  const [excludedCategories, setExcludedCategories] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [includedCampaigns, setIncludedCampaigns] = useState<Campaign[]>([])
  const [excludedCampaigns, setExcludedCampaigns] = useState<Campaign[]>([])
  const [spamLeadsDisclaimerToggle, setSpamLeadsDisclaimerToggle] = useState<boolean>(true)
  const [insufficientDataSourcesDisclaimerToggle, setInsufficientDataSourcesDisclaimerToggle] =
    useState<boolean>(true)
  const [excludedCategoriesDisclaimerToggle, setExcludedCategoriesDisclaimerToggle] =
    useState<boolean>(true)
  const [isTitleEditable, setIsTitleEditable] = useState<boolean>(false)
  const [moduleTitle, setModuleTitle] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const moduleConfig = getModuleComponentFromName(item?.module)
  const showCategories = moduleConfig?.isConversionModule || moduleConfig?.isConversionRateModule
  const adsModules = [
    'Facebook Ads',
    'Google Ads',
    'Google Adwords Ads',
    'Google Display Ads',
    'Google VLA Ads',
  ]

  const showCampaigns = adsModules.includes(moduleConfig?.group)
  const showSpamLeadsDisclaimer = moduleConfig?.showSpamLeadsDisclaimer
  const showInsufficientDataSourcesDisclaimer = moduleConfig?.showInsufficientDataSourcesDisclaimer
  const showExcludedCategoriesDisclaimer = moduleConfig?.showExcludedCategoriesDisclaimer
  const showChartTypes =
    moduleConfig?.excludedChartTypes?.length < 2 && !moduleConfig?.isCustomChart

  const currentModule = tabLayouts[selectedTab]?.find((tab) => tab.module === item.module)
  const currentTitle = currentModule?.filters?.title || moduleConfig?.title
  const currentShowSpamLeadsDisclaimer = currentModule?.filters?.showSpamLeadsDisclaimer
  const currentShowInsufficientDataSourcesDisclaimer =
    currentModule?.filters?.showInsufficientDataSourcesDisclaimer
  const currentShowExcludedCategoriesDisclaimer =
    currentModule?.filters?.showExcludedCategoriesDisclaimer

  const handleChartTypeChange = (event: DropdownChangeEvent): void => {
    handleSetChartTypes(item.module, event.target.value)
  }

  // Exclude certain chart types that don't make sense for the data
  const dropdownOptions = ['Pie', 'Bar', 'Line'].filter((chart) => {
    return !moduleConfig?.excludedChartTypes?.includes(chart as ChartType)
  })

  const ModuleComponent = moduleComponents[item?.module]
    ? item?.module && moduleComponents[item?.module]?.component
    : null

  const getAllCategories = async (): Promise<void> => {
    try {
      setLoading(true)
      const URL = Routes.categories_dealership_lead_clusters_path(dealership?.id)
      const response = await fetch(URL)
      const data = await response.json()
      if (response.ok) {
        setAllCategories(data)

        setIncludedCategories(() => {
          const newIncludedCategories =
            currentModule?.filters?.leadConversionCategories || salesCategories

          // Set excludedCategories based on the new includedCategories
          setExcludedCategories(
            data.filter((category: string) => !newIncludedCategories.includes(category))
          )

          return newIncludedCategories
        })
      } else console.error('Error:', data)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddIncludedCategory = (selectedOptions: { value: string }[]): void => {
    // Get the currently selected values
    const selectedValues = selectedOptions.map((option) => option.value)

    // Update included categories
    setIncludedCategories(selectedValues)

    // Determine categories that have been removed
    const removedCategories = allCategories.filter((category) => !selectedValues.includes(category))

    // Update excluded categories
    setExcludedCategories([...removedCategories])
  }

  const handleHideDialog = (categories: string[]): void => {
    setIsDialogVisible(false)
    if (showCategories) {
      handleUpdateTabLayoutsWithCategories(categories)
    }
    if (showCampaigns) {
      handleUpdateTabLayoutsWithCampaigns(includedCampaigns)
    }
  }

  const handleUpdateTabLayoutsTitle = (title: string): void => {
    if (title === '') {
      setModuleTitle(currentTitle)
      return
    }
    if (title.length > 50) {
      alert('Title must be less than 50 characters. You have ' + title.length + ' characters.')
      setModuleTitle(currentTitle)
      return
    }
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((tab) => {
        if (tab.module === item.module) {
          return {
            ...tab,
            filters: {
              ...tab.filters,
              title,
            },
          }
        }
        return tab
      })

      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
    setIsTitleEditable(false)
  }

  const handleUpdateTabLayoutsWithCategories = (categories: string[]): void => {
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((tab) => {
        if (tab.module === item.module) {
          return {
            ...tab,
            filters: {
              ...tab.filters,
              leadConversionCategories: categories,
            },
          }
        }
        return tab
      })

      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleUpdateTabLayoutsWithCampaigns = (campaigns: Campaign[]): void => {
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((tab) => {
        if (tab.module === item.module) {
          return {
            ...tab,
            filters: {
              ...tab.filters,
              adCampaigns: campaigns,
            },
          }
        }
        return tab
      })

      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleUpdateTabLayoutsSpamLeadsDisclaimer = (checked: boolean): void => {
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((tab) => {
        if (tab.module === item.module) {
          return {
            ...tab,
            filters: {
              ...tab.filters,
              showSpamLeadsDisclaimer: checked,
            },
          }
        }
        return tab
      })

      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleUpdateTabLayoutsInsufficientDataSourcesDisclaimer = (checked: boolean): void => {
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((tab) => {
        if (tab.module === item.module) {
          return {
            ...tab,
            filters: {
              ...tab.filters,
              showInsufficientDataSourcesDisclaimer: checked,
            },
          }
        }
        return tab
      })

      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }
  const handleUpdateTabLayoutsExcludedCategoriesDisclaimer = (checked: boolean): void => {
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((tab) => {
        if (tab.module === item.module) {
          return {
            ...tab,
            filters: {
              ...tab.filters,
              showExcludedCategoriesDisclaimer: checked,
            },
          }
        }
        return tab
      })

      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  // Custom MultiValueRemove component to disable the delete button when only one item is selected
  const MultiValueRemove = (props) => {
    // Disable the remove button if there's only one item left
    if (props.selectProps.value.length === 1) {
      return null
    }
    // Otherwise, render the default remove button
    return <components.MultiValueRemove {...props} />
  }

  const EditTitleComponent = () => {
    return (
      <div className="d-flex text-left pb-2">
        <h5 className="mr-3">Title:</h5>
        {isTitleEditable ? (
          <input
            ref={inputRef}
            type="text"
            value={moduleTitle}
            onChange={(e) => setModuleTitle(e.target.value)}
            onBlur={() => {
              handleUpdateTabLayoutsTitle(moduleTitle)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleUpdateTabLayoutsTitle(moduleTitle)
              }
            }}
            autoFocus
          />
        ) : (
          <span className="cursor-pointer" onClick={() => setIsTitleEditable(!isTitleEditable)}>
            {moduleTitle}
          </span>
        )}
        {!isTitleEditable ? (
          <i
            className="fal fa-edit pt-1 ml-2 cursor-pointer"
            onClick={() => setIsTitleEditable(!isTitleEditable)}
          ></i>
        ) : null}
      </div>
    )
  }

  useEffect(() => {
    if (showCategories && isDialogVisible) {
      fetchModuleData({
        module: item?.module,
        timeRange: timeRange,
        leadConversionCategories: includedCategories,
        selectedTab,
        dashboardLevel,
        dashboardLevelLoaded,
        dealership,
        website,
        manufacturer,
      }).then((data) => {
        setEditingPreviewData((prevState) => {
          return {
            ...prevState,
            ...data,
          }
        })
      })
    }
  }, [includedCategories, excludedCategories])

  return (
    <>
      <div
        onClick={() => {
          setIsDialogVisible(true)
          setModuleTitle(currentTitle)
          if (showCategories) {
            getAllCategories()
          }
          if (showSpamLeadsDisclaimer && typeof currentShowSpamLeadsDisclaimer !== 'undefined') {
            setSpamLeadsDisclaimerToggle(currentShowSpamLeadsDisclaimer)
          }
          if (
            showInsufficientDataSourcesDisclaimer &&
            typeof currentShowInsufficientDataSourcesDisclaimer !== 'undefined'
          ) {
            setInsufficientDataSourcesDisclaimerToggle(currentShowInsufficientDataSourcesDisclaimer)
          }
          if (
            showExcludedCategoriesDisclaimer &&
            typeof currentShowExcludedCategoriesDisclaimer !== 'undefined'
          ) {
            setExcludedCategoriesDisclaimerToggle(currentShowExcludedCategoriesDisclaimer)
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <i className="fal fa-edit"></i>
        <span className="ml-2 mr-3" style={{ color: '#5f6269' }}>
          Edit
        </span>
      </div>
      {!loading ? (
        <Dialog
          header={`Customise ${moduleConfig?.title} Module`}
          visible={isDialogVisible}
          style={{ width: '70vw', minHeight: '40vw', textAlign: 'center' }}
          onHide={() => handleHideDialog(includedCategories)}
          dismissableMask
        >
          <div className="px-4 pb-2">
            <EditTitleComponent />
            {showChartTypes ? (
              <div className="d-flex align-items-center m-0 pb-2">
                <h5 className="mr-3 mb-0">Chart type:</h5>
                <Dropdown
                  onChange={handleChartTypeChange}
                  value={chartType}
                  options={dropdownOptions}
                ></Dropdown>
              </div>
            ) : null}
            {showCategories ? (
              <div className="d-flex w-75">
                <span>
                  <h5 className="mr-3">Categories:</h5>
                </span>
                <Select
                  isMulti
                  isClearable={false}
                  placeholder="Select Category"
                  defaultValue={includedCategories.map((category) => ({
                    label: category,
                    value: category,
                  }))}
                  options={excludedCategories.map((category) => ({
                    label: category,
                    value: category,
                  }))}
                  className="mr-2"
                  onChange={handleAddIncludedCategory}
                  onKeyDown={(event) => {
                    // Prevent clearing the last item using backspace
                    if (event.key === 'Backspace' && includedCategories.length === 1) {
                      event.preventDefault()
                    }
                  }}
                  components={{ MultiValueRemove }} // Override the default component
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </div>
            ) : null}
            {showCampaigns ? (
              <FilterCampaigns
                item={item}
                moduleConfig={moduleConfig}
                includedCampaigns={includedCampaigns}
                setIncludedCampaigns={setIncludedCampaigns}
                excludedCampaigns={excludedCampaigns}
                setExcludedCampaigns={setExcludedCampaigns}
              />
            ) : null}
            {/** Disclaimer Toggles */}
            {showSpamLeadsDisclaimer ? (
              <div className="d-flex justify-content-start mt-2">
                <Switch
                  label="Show spam leads disclaimer"
                  value={spamLeadsDisclaimerToggle}
                  onChange={(e) => {
                    setSpamLeadsDisclaimerToggle(e.target.checked)
                    handleUpdateTabLayoutsSpamLeadsDisclaimer(e.target.checked)
                  }}
                  id="show-spam-leads-disclaimer-switch"
                />
              </div>
            ) : null}
            {showExcludedCategoriesDisclaimer ? (
              <div className="d-flex justify-content-start mt-2">
                <Switch
                  label="Show excluded categories disclaimer"
                  value={excludedCategoriesDisclaimerToggle}
                  onChange={(e) => {
                    setExcludedCategoriesDisclaimerToggle(e.target.checked)
                    handleUpdateTabLayoutsExcludedCategoriesDisclaimer(e.target.checked)
                  }}
                  id="show-excluded-categories-disclaimer-switch"
                />
              </div>
            ) : null}
            {showInsufficientDataSourcesDisclaimer ? (
              <div className="d-flex justify-content-start mt-2">
                <Switch
                  label="Show insifficient data disclaimer"
                  value={insufficientDataSourcesDisclaimerToggle}
                  onChange={(e) => {
                    setInsufficientDataSourcesDisclaimerToggle(e.target.checked)
                    handleUpdateTabLayoutsInsufficientDataSourcesDisclaimer(e.target.checked)
                  }}
                  id="show-insufficient-data-disclaimer-switch"
                />
              </div>
            ) : null}
            <h5 className="mt-3">Module preview:</h5>
            <div className="editModule">
              <ModuleComponent
                isOverlay={false}
                selectedLocation={null}
                timeRange={timeRange}
                item={item}
                timeRangeType={null}
              />
              {showSpamLeadsDisclaimer && spamLeadsDisclaimerToggle && <SpamLeadsDisclaimer />}
              {showExcludedCategoriesDisclaimer && excludedCategoriesDisclaimerToggle && (
                <ExcludedCategoriesDisclaimer excludedCategories={excludedCategories} />
              )}
              {showInsufficientDataSourcesDisclaimer && insufficientDataSourcesDisclaimerToggle && (
                <InsufficientDataSourcesDisclaimer />
              )}
            </div>
            <button
              className={'btn btn-success mt-4'}
              onClick={() => handleHideDialog(includedCategories)}
            >
              Finish
            </button>
          </div>
        </Dialog>
      ) : null}
    </>
  )
}

export default EditModule
