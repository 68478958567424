import { useCallback, useEffect, useRef, useState } from 'react'
import {
  DashboardLevel,
  TimeRange,
  Location,
  TimeRangeOverrideGroup,
  Dealership,
  Website,
  Manufacturer,
  ModuleItem,
} from './types'
import { fetchModuleLayoutByTab, getModuleComponentFromName } from './utils'
import { fetchModuleData, saveConfig, setModuleLayouts } from './api'
import { getYearlyTimeRange } from '../entries/TimeRange'
import { useAnalyticsDashboard, useConfig, useTabs } from './contexts/hooks'
import { overrideExcludedModules } from './constants'

export const useViewportData = (
  module: string,
  timeRange: TimeRange,
  selectedLocation: Location
): {
  data: any
  loading: boolean
  viewportRef: React.RefObject<HTMLDivElement>
} => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewed, setViewed] = useState<boolean>(false)
  const viewportRef = useRef<HTMLDivElement>(null)
  const isInViewport = useOnScreen(viewportRef)
  const { config }: { config: { defaultTimeRangeOverride?: string[] | TimeRangeOverrideGroup[] } } =
    useConfig()
  const { dashboardLevel, dashboardLevelLoaded, dealership, manufacturer, website } =
    useAnalyticsDashboard()
  const { selectedTab } = useTabs()
  const moduleConfig = getModuleComponentFromName(module)

  function shouldOverride(group: TimeRangeOverrideGroup): boolean {
    if (overrideExcludedModules.includes(module)) return false
    return moduleConfig?.group === group && config?.defaultTimeRangeOverride?.includes(group)
  }

  let overrideToYearlyTimeRange = false
  switch (true) {
    case shouldOverride('Google Adwords Ads'):
    case shouldOverride('Google Display Ads'):
    case shouldOverride('Google VLA Ads'):
    case shouldOverride('Facebook Ads'):
    case shouldOverride('Google Ads'):
    case shouldOverride('Google Analytics'):
      overrideToYearlyTimeRange = true
      break
    default:
      break
  }

  const yearlyTimeRange = overrideToYearlyTimeRange ? getYearlyTimeRange() : undefined

  const loadData = async (params: {
    module: string
    timeRange: TimeRange
    yearlyTimeRange?: TimeRange | undefined
    selectedLocation: Location
    dashboardLevel: DashboardLevel
    dashboardLevelLoaded: boolean
    selectedTab: number
    dealership?: Dealership
    manufacturer?: Manufacturer
    website?: Website
    signal?: AbortSignal
  }) => {
    setLoading(true)
    try {
      const newData = await fetchModuleData(params)
      if (newData) {
        setData(newData)
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching data:', error)
      }
    } finally {
      setLoading(false)
      setViewed(true)
    }
  }

  // Load the data when the element is in the viewport and hasn't been viewed yet.
  useEffect(() => {
    // const controller = new AbortController()
    // const signal = controller.signal

    if (isInViewport && !viewed && dashboardLevelLoaded && !loading) {
      loadData({
        module,
        timeRange,
        yearlyTimeRange,
        selectedLocation,
        dashboardLevel,
        dashboardLevelLoaded,
        selectedTab,
        dealership,
        manufacturer,
        website,
        // signal,
      })
    }

    // return () => controller.abort()
  }, [
    isInViewport,
    viewed,
    module,
    timeRange,
    selectedLocation,
    dashboardLevel,
    dashboardLevelLoaded,
    selectedTab,
    config?.defaultTimeRangeOverride,
    dealership,
    manufacturer,
    website,
  ])

  // Reload data when timeRange, selectedLocation or dashboardLevel changes,
  // regardless of whether the component has been viewed or not.
  useEffect(() => {
    if (viewed && dashboardLevelLoaded) {
      loadData({
        module,
        timeRange,
        yearlyTimeRange,
        selectedLocation,
        dashboardLevel,
        dashboardLevelLoaded,
        selectedTab,
        dealership,
        manufacturer,
        website,
      })
    }
  }, [
    module,
    timeRange,
    selectedLocation,
    dashboardLevel,
    selectedTab,
    config?.defaultTimeRangeOverride,
  ])

  return { data, loading, viewportRef }
}

export function useOnScreen(ref: React.RefObject<HTMLDivElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const useSaveConfig: () => () => Promise<void> = () => {
  const { config } = useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    manufacturer,
    website,
    analyticsBlock,
  } = useAnalyticsDashboard()

  return async () => {
    await saveConfig(
      config,
      dashboardLevel,
      dashboardLevelLoaded,
      analyticsBlock,
      dealership,
      manufacturer,
      website
    )
  }
}

export const useSetModuleLayouts: () => (layouts?: {
  [key: number]: ModuleItem[]
}) => Promise<void> = () => {
  const { config } = useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    manufacturer,
    website,
    analyticsBlock,
  } = useAnalyticsDashboard()
  const { tabLayouts, tabTitles } = useTabs()

  return async (layouts) => {
    const layoutsToSave = layouts || tabLayouts
    await setModuleLayouts(
      layoutsToSave,
      tabTitles,
      config,
      dashboardLevel,
      dashboardLevelLoaded,
      analyticsBlock,
      dealership,
      manufacturer,
      website
    )
  }
}

export const useNameBasedOnLevel = (): string | undefined => {
  const { dashboardLevel, dashboardLevelLoaded, dealership, website } = useAnalyticsDashboard()
  if (!dashboardLevelLoaded) {
    return undefined
  }
  if (dashboardLevel === 'Dealership') {
    return dealership?.name
  } else if (dashboardLevel === 'Website') {
    return website?.name
  } else {
    return undefined
  }
}

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowWidth
}

export const useFetchModuleLayoutByTab: () => (tabIndex: number) => ModuleItem[] = () => {
  const { analyticsBlock } = useAnalyticsDashboard()

  return useCallback(
    (tabIndex: number) => {
      const layouts = fetchModuleLayoutByTab(tabIndex, analyticsBlock)
      return layouts
    },
    [analyticsBlock]
  )
}
