import showToast from '../shared/ShowToast'

import type {
  AnalyticsBlock,
  Dealership,
  DashboardLevel,
  Manufacturer,
  ModuleComponentConfig,
  ModuleItem,
  Website,
  NotificationType,
  AnalyticsBlockConfig,
} from './types'
import { RefObject } from 'react'
import { Toast } from 'primereact/toast'
import { facebookAdsModules, ga4Modules, rootUrl } from './constants'
import { moduleComponents } from './config'

export function getModuleComponentFromName(name: string): ModuleComponentConfig {
  return moduleComponents[name]
}

export function getManufacturer(): Manufacturer {
  const rootElement = document.getElementById('app_root')
  const elementAttribute = rootElement?.getAttribute('manufacturer')
  const manufacturer = JSON.parse(elementAttribute)
  return manufacturer
}
export function getWebsite(): Website {
  const rootElement = document.getElementById('app_root')
  const elementAttribute = rootElement?.getAttribute('website')
  const website = JSON.parse(elementAttribute)
  return website
}

// Fetch the module layout
export function fetchModuleLayoutByTab(
  tabIndex: number,
  analyticsBlock: AnalyticsBlock
): ModuleItem[] {
  return analyticsBlock?.data?.tabs?.find((tab) => tab.id === tabIndex)?.layout
}

// Get tabs
export function getTabTitles(analyticsBlock: AnalyticsBlock): string[] {
  const tabTitles = analyticsBlock?.data?.tabs?.map((tab) => tab.title)
  return tabTitles?.length ? tabTitles : ['Tab 1']
}

// Creates an array of ModuleItem's, if the visibleLevels preoperty includes the dashboardLevel
export const getModuleItemsBasedOnLevel = (dashboardLevel: DashboardLevel): ModuleItem[] => {
  if (dashboardLevel === 'Website') {
    const website = getWebsite()
    // Create an array of excluded modules
    let excludedModules: string[] = []

    // If the website doesn't have a GA profile, exclude the GA4 modules
    if (!website?.gaProfileExists) {
      excludedModules = excludedModules.concat(ga4Modules)
    }

    // If the website doesn't have a Facebook account, exclude the Facebook Ads modules
    // Might need to adjust when we bring in google ads campaigns
    if (!website?.hasCampaigns) {
      excludedModules = excludedModules.concat(facebookAdsModules)
    }

    return Object.keys(moduleComponents)
      .filter((key) => !excludedModules.includes(key))
      .reduce((acc, key) => {
        if (moduleComponents[key].visibleLevels.includes(dashboardLevel)) {
          acc.push({ module: key })
        }
        return acc
      }, [])
  }

  return Object.keys(moduleComponents).reduce((acc, key) => {
    if (moduleComponents[key].visibleLevels.includes(dashboardLevel)) {
      acc.push({ module: key })
    }
    return acc
  }, [])
}

// convert leads_chart to Leads Chart for example
export function snakeCaseToTitleCase(module: string): string {
  return module
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// convert orange-motor-group to Orange Motor Group for example
export function slugToTitle(module: string): string {
  return module
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// Merges objects based on lodash merging
export function mergeDeep(...objects: Array<object>): object {
  const isObject = (obj: any) =>
    obj !== null && obj !== undefined && typeof obj === 'object' && !Array.isArray(obj)

  return objects.filter(isObject).reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })

    return prev
  }, {})
}

export const handleNotificationEvent = (
  notification: RefObject<Toast>,
  message: string,
  type: NotificationType
): void => {
  showToast(notification, type, capitalize(type.toString()), message)
}

export function linkFacebookAccount(dealershipSlug: string | undefined): void {
  const apiUrl = 'https://www.facebook.com/v5.0/dialog/oauth'
  const params = new URLSearchParams({
    client_id: '305713216982753',
    redirect_uri: `${rootUrl}/facebook/oauth`,
    scope: 'ads_read,read_insights',
    state: `asset_type=facebook_ad_account&dealership_slug=${dealershipSlug}`,
  })

  window.open(`${apiUrl}?${params.toString()}`, '_blank')
}

export const isEmptyObject = (value: unknown): boolean => {
  return typeof value === 'object' && value !== null && Object.keys(value).length === 0
}

export const getGroupedModules = (group: string): ModuleItem[] => {
  return Object.keys(moduleComponents)
    .filter((key) => moduleComponents[key].group === group)
    .map((key) => ({ module: key }))
}

export function getColor(value: string | number, reverse: boolean = false): string {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value
  if (parsedValue > 0) {
    return reverse ? 'text-danger' : 'text-success'
  } else if (parsedValue < 0) {
    return reverse ? 'text-success' : 'text-danger'
  } else {
    return ''
  }
}

export function capitalize(string: string) {
  if (typeof string !== 'string') return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}
