import {
  AnalyticsBlockConfig,
  Campaign,
  Dealership,
  DashboardLevel,
  Location,
  Manufacturer,
  ModuleItem,
  TimeRange,
  Website,
  AnalyticsBlock,
} from './types'
import * as Routes from '../../routes'
import { getManufacturer, getWebsite } from './utils'
import { standardHeaders } from '../entries/utils'
import { ga4Modules, isFacebookAdsModule } from './constants'

export async function fetchModuleData({
  module,
  timeRange,
  yearlyTimeRange,
  selectedLocation,
  category,
  selectedTab,
  leadConversionCategories,
  showTimePeriodTotal,
  adCampaigns,
  dashboardLevel,
  dashboardLevelLoaded,
  dealership,
  manufacturer,
  website,
  signal,
}: {
  module: string
  timeRange?: TimeRange
  yearlyTimeRange?: TimeRange | undefined
  selectedLocation?: Location
  category?: string
  selectedTab?: number
  leadConversionCategories?: string[]
  showTimePeriodTotal?: boolean
  adCampaigns?: Campaign[]
  dashboardLevel?: DashboardLevel
  dashboardLevelLoaded?: boolean
  dealership?: Dealership | undefined
  manufacturer?: Manufacturer | undefined
  website?: Website | undefined
  signal?: AbortSignal
}): Promise<any> {
  if (!dashboardLevelLoaded) {
    return Promise.resolve(null)
  }

  const URLParams = {
    module: module,
    start: yearlyTimeRange && !showTimePeriodTotal ? yearlyTimeRange.start : timeRange?.start,
    end: yearlyTimeRange && !showTimePeriodTotal ? yearlyTimeRange.end : timeRange?.end,
    timeframe:
      yearlyTimeRange && !showTimePeriodTotal ? yearlyTimeRange.timeframe : timeRange?.timeframe,
    location_id: selectedLocation?.id === -1 ? null : selectedLocation?.id,
    category: category || undefined,
    tab: selectedTab,
    lead_conversion_categories: leadConversionCategories || undefined,
    show_time_period_total: showTimePeriodTotal || false,
    ad_campaigns: adCampaigns || undefined,
  }

  // Temporary
  const manufacturerFromDOM = getManufacturer()
  const websiteFromDOM = getWebsite()

  const dealershipURL =
    dealership && dashboardLevel === 'Dealership' && dashboardLevelLoaded
      ? Routes.dealership_analytics_blocks_path(dealership.slug, URLParams)
      : undefined

  const manufacturerURL = manufacturerFromDOM
    ? // manufacturer && dashboardLevel === 'Manufacturer' && dashboardLevelLoaded
      Routes.manufacturer_analytics_blocks_path(manufacturerFromDOM.slug, URLParams)
    : undefined

  const websiteURL = websiteFromDOM
    ? // website && dashboardLevel === 'Website' && dashboardLevelLoaded
      Routes.website_analytics_blocks_path(websiteFromDOM.slug, URLParams)
    : undefined

  const URL: string | undefined = manufacturerURL || dealershipURL || websiteURL || undefined

  let data: unknown = null
  try {
    const response = await fetch(URL, { signal })
    if (!response.ok) {
      throw new Error('Error fetching data')
    }
    // console.log(await response.json()) // For debugging
    data = await response.json()
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch aborted')
    } else {
      console.error('Fetch error: ', error)
    }
  }

  return await data
}

// Save the module layout
export async function setModuleLayouts(
  tabLayouts: { [key: number]: ModuleItem[] },
  tabTitles: string[],
  config: AnalyticsBlockConfig,
  dashboardLevel: DashboardLevel,
  dashboardLevelLoaded: boolean,
  analyticsBlock: AnalyticsBlock,
  dealership: Dealership,
  manufacturer: Manufacturer,
  website: Website
): Promise<void> {
  if (!dashboardLevelLoaded) {
    return Promise.resolve(null)
  }

  const tabData = Object.values(tabLayouts).map((layout, index) => {
    // For testing purposes
    const modifiedLayout = layout?.map((item, itemIndex) => {
      // Change to true to test
      if (false) {
        return {
          ...item,
          id: `${itemIndex}`,
        }
      }
      return item
    })

    return {
      id: index,
      title: tabTitles[index] || `Tab ${index + 1}`,
      layout: modifiedLayout,
    }
  })

  analyticsBlock.data = {
    tabs: tabData,
    config,
  }

  const manufacturerFromDOM = getManufacturer()
  const websiteFromDOM = getWebsite()

  const dealershipURL =
    dealership && dashboardLevel === 'Dealership' && dashboardLevelLoaded
      ? Routes.update_block_dealership_analytics_blocks_path(dealership.slug)
      : undefined

  const manufacturerURL = manufacturerFromDOM
    ? Routes.update_block_manufacturer_analytics_blocks_path(manufacturerFromDOM?.slug)
    : undefined

  const websiteURL = websiteFromDOM
    ? Routes.update_block_website_analytics_blocks_path(websiteFromDOM?.slug)
    : undefined

  const URL: string | undefined = manufacturerURL || websiteURL || dealershipURL || undefined

  try {
    const response = await fetch(URL, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify({ analytics_block: analyticsBlock }),
    })

    if (!response.ok) {
      throw new Error('Error saving order')
    }
    // TODO: Temporary
    if (dashboardLevel === 'Manufacturer' || dashboardLevel === 'Website') {
      const rootElement = document.getElementById('app_root')
      rootElement.setAttribute('analytics_block', JSON.stringify(analyticsBlock))
    }
  } catch (error) {
    console.error(error)
  }
}

export async function saveConfig(
  config: AnalyticsBlockConfig,
  dashboardLevel: DashboardLevel,
  dashboardLevelLoaded: boolean,
  analyticsBlock: AnalyticsBlock,
  dealership: Dealership,
  manufacturer: Manufacturer,
  website: Website
): Promise<void> {
  if (!dashboardLevelLoaded) {
    return Promise.resolve(null)
  }
  analyticsBlock.data.config = config

  const manufacturerFromDOM = getManufacturer()
  const websiteFromDOM = getWebsite()

  const dealershipURL =
    dealership && dashboardLevel === 'Dealership' && dashboardLevelLoaded
      ? Routes.update_block_dealership_analytics_blocks_path(dealership.slug)
      : undefined

  const manufacturerURL = manufacturerFromDOM
    ? Routes.update_block_manufacturer_analytics_blocks_path(manufacturerFromDOM?.slug)
    : undefined

  const websiteURL = websiteFromDOM
    ? Routes.update_block_website_analytics_blocks_path(websiteFromDOM?.slug)
    : undefined

  const URL: string = manufacturerURL || websiteURL || dealershipURL

  try {
    const response = await fetch(URL, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify({ analytics_block: analyticsBlock }),
    })

    if (!response.ok) {
      throw new Error('Error saving order')
    }
    // TODO: Temporary
    if (dashboardLevel === 'Manufacturer' || dashboardLevel === 'Website') {
      const rootElement = document.getElementById('app_root')
      rootElement.setAttribute('analytics_block', JSON.stringify(analyticsBlock))
    }
  } catch (error) {
    console.error(error)
  }
}

export const getTotalForTimePeriod = async (
  module: string,
  timeRange: TimeRange,
  selectedTab: number,
  dashboardLevel: DashboardLevel,
  dashboardLevelLoaded: boolean,
  dealership: Dealership | undefined,
  website: Website | undefined,
  manufacturer: Manufacturer | undefined,
  signal?: AbortSignal,
  filteredCampaigns: Campaign[] | undefined = undefined
): Promise<number> => {
  const data: { [key: string]: number } = await fetchModuleData({
    module,
    timeRange: timeRange,
    showTimePeriodTotal: true,
    adCampaigns: filteredCampaigns ? filteredCampaigns : undefined,
    selectedTab,
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    website,
    manufacturer,
    signal,
  })

  return data?.[module]
}

export const getPreviousTimePeriod = async (
  module: string,
  timeRange: TimeRange,
  selectedTab: number,
  dashboardLevel: DashboardLevel,
  dashboardLevelLoaded: boolean,
  dealership: Dealership | undefined,
  website: Website | undefined,
  manufacturer: Manufacturer | undefined,
  signal?: AbortSignal,
  filteredCampaigns: Campaign[] | undefined = undefined
): Promise<{ previousPeriod: number; lastYear: number; total: number | string }> => {
  if (!isFacebookAdsModule(module) && !module.includes('google') && !ga4Modules.includes(module)) {
    return Promise.resolve({ previousPeriod: 0, lastYear: 0, total: 0 })
  }
  const data: { previousPeriod: number; lastYear: number } = await fetchModuleData({
    module,
    timeRange: timeRange,
    showTimePeriodTotal: true,
    adCampaigns: filteredCampaigns ? filteredCampaigns : undefined,
    selectedTab,
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    website,
    manufacturer,
    signal,
  })

  return data?.[module]
}

export async function automateCampaignDeletion(dealershipSlug: string): Promise<void> {
  try {
    const URL = Routes.remove_expired_campaigns_dealership_social_ad_accounts_path(dealershipSlug)
    const response = await fetch(URL, {
      method: 'DELETE',
      headers: standardHeaders,
    })

    if (!response.ok) {
      throw new Error('Error removing campaigns')
    }
  } catch (error) {
    console.error(error)
  }
}

export async function automateAddCampaigns(dealershipSlug: string): Promise<void> {
  try {
    const URL =
      Routes.auto_assign_social_ad_campaigns_dealership_social_ad_accounts_path(dealershipSlug)
    const response = await fetch(URL)
    if (!response.ok) {
      throw new Error('Error adding campaigns')
    }
  } catch (error) {
    console.error(error)
  }
}

export const fetchAnalyticsBlock = async (
  dashboardLevel: DashboardLevel,
  dashboardLevelLoaded: boolean,
  dealership: Dealership,
  manufacturer: Manufacturer,
  website: Website
): Promise<AnalyticsBlock> => {
  // TODO: Temporary
  if (dashboardLevel === 'Manufacturer' || dashboardLevel === 'Website') {
    return Promise.resolve(null)
  }

  if (!dashboardLevelLoaded) {
    return Promise.resolve(null)
  }

  const dealershipURL =
    dealership && dashboardLevel === 'Dealership' && dashboardLevelLoaded
      ? Routes.analytics_block_dealership_analytics_blocks_path(dealership.slug)
      : undefined

  const URL = dealershipURL || undefined

  const response = await fetch(URL)

  if (!response.ok) {
    throw new Error('Error fetching analytics block')
  }
  const analyticsBlock = await response.json()
  return analyticsBlock
}
