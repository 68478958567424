import LoadingBoxes from '../../../../entries/LoadingBoxes'
import ChartComponent from '../../../features/Charts/components/ChartComponent'
import type { ModuleProps, TimeRange } from '../../../types'
import { useViewportData } from '../../../hooks'
import { getModuleComponentFromName, isEmptyObject } from '../../../utils'
import { TotalForTimePeriod } from '../../Charts/components/TotalForTimePeriod'
import { useAnalyticsDashboard, usePreviewData, useTabs } from '../../../contexts/hooks'
import PreviousTimePeriod from '../../Charts/components/PreviousTimePeriod'
import { usePreviousTimePeriod } from '../../Charts/hooks'

const allowedModulesForTotal = [
  'Google Adwords Ads',
  'Google Display Ads',
  'Google VLA Ads',
  'Google Ads',
  'Facebook Ads',
  'Google Analytics',
]

const PaymentsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.number_of_payments > 0 ? (
        <ul className="mt-4">
          <li>
            {data.number_of_payments} payments {timeframe}
          </li>
          <li>
            {data.total_amount} total in payments {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const SoldCarsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.total_sold_count > -1 ? (
        <ul className="mt-4">
          <li>
            {data.total_sold_count} total cars withdrawn {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const GenericModuleComponent: React.FC<ModuleProps> = ({
  timeRange,
  item,
  selectedLocation,
  isOverlay = false,
}) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const moduleConfig = getModuleComponentFromName(item?.module)
  const { editModeEnabled, dashboardLevel } = useAnalyticsDashboard()
  const { editingPreviewData, editingPreviewTotal } = usePreviewData()
  const {
    totalForTimePeriod,
    previousPeriod,
    lastYear,
    loading: previousPeriodLoading,
  } = usePreviousTimePeriod(item?.module)

  // If the module is any Ads from FB/Google, we want to show the previous period data, but don't load anything until the previous period data is loaded
  const globalLoading =
    loading || allowedModulesForTotal.includes(moduleConfig?.group) ? previousPeriodLoading : false

  // TODO: Improve this logic or maybe move to BE
  // Show preview data first, if it exists and isn't empty. Then check is data exists and isn't empty. If they are empty set to []
  const notEmptyPreviewData = isEmptyObject(editingPreviewData?.[item?.module])
    ? []
    : editingPreviewData?.[item?.module]

  const notEmptyData = isEmptyObject(data?.[item?.module]) ? [] : data?.[item?.module]

  const dataToShow =
    editModeEnabled && editingPreviewData?.[item?.module] ? notEmptyPreviewData : notEmptyData

  // do not get this total if the module is Google Analytics for Dealership level or Pie/Bar chart types as they are not eligible for total
  const notEligibleForTotal =
    moduleConfig?.group === 'Google Analytics' &&
    (dashboardLevel === 'Dealership' || ['Pie', 'Bar'].includes(moduleConfig?.defaultChartType))

  return (
    <div className="h-100" style={{ minHeight: 'inherit' }} ref={viewportRef}>
      {!globalLoading && dataToShow ? (
        <>
          {allowedModulesForTotal.includes(moduleConfig?.group) && !notEligibleForTotal ? (
            <TotalForTimePeriod
              item={item}
              moduleConfig={moduleConfig}
              totalForTimePeriod={totalForTimePeriod}
              editingPreviewTotal={editingPreviewTotal}
            />
          ) : null}
          <ChartComponent
            chartData={dataToShow || []}
            isOverlay={isOverlay}
            module={item?.module}
          />
          {allowedModulesForTotal.includes(moduleConfig?.group) && !notEligibleForTotal ? (
            <PreviousTimePeriod
              previousPeriod={previousPeriod}
              lastYear={lastYear}
              moduleConfig={moduleConfig}
            />
          ) : null}
        </>
      ) : (
        <LoadingBoxes />
      )}
      {moduleConfig?.title === 'Payments' ? (
        <PaymentsFooter data={data} timeRange={timeRange} />
      ) : null}
      {moduleConfig?.title === 'Cars Sold' ? (
        <SoldCarsFooter data={data} timeRange={timeRange} />
      ) : null}
    </div>
  )
}

export default GenericModuleComponent
