export const SpamLeadsDisclaimer = () => (
  <small className="d-block text-secondary text-left">*Excludes leads marked as "spam"</small>
)

export const ExcludedCategoriesDisclaimer: React.FC<{ excludedCategories: string[] }> = ({
  excludedCategories,
}) => (
  <small className="d-block text-secondary text-left">
    **Excluded Categories: {excludedCategories.join(', ')}
  </small>
)

export const InsufficientDataSourcesDisclaimer = () => (
  <small className="d-block text-secondary text-left">
    ***Data sources with less than 5 leads will be excluded
  </small>
)
