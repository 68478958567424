import * as Routes from '../../../../../routes'
import { useEffect, useState } from 'react'
import { ModuleComponentConfig, ModuleItem, Campaign } from '../../../types'
import Select, { components } from 'react-select'
// import { getTotalForTimePeriod, fetchModuleData, getPreviousTimePeriod } from '../../../api'
import { fetchModuleData, getPreviousTimePeriod } from '../../../api'
import { useParams } from 'react-router-dom'
import {
  useAnalyticsDashboard,
  usePreviewData,
  useTabs,
  useTimeRangeLocation,
} from '../../../contexts/hooks'

import { MultiValueRemove, MultiValue, Option, MultiValueLabel } from './CustomComponents'
import { capitalize } from '../../../utils'

const FilterCampaigns: React.FC<{
  item: ModuleItem
  moduleConfig: ModuleComponentConfig
  includedCampaigns: Campaign[]
  excludedCampaigns: Campaign[]
  setIncludedCampaigns: (campaigns: Campaign[]) => void
  setExcludedCampaigns: (campaigns: Campaign[]) => void
}> = ({
  item,
  moduleConfig,
  includedCampaigns,
  excludedCampaigns,
  setIncludedCampaigns,
  setExcludedCampaigns,
}) => {
  const { timeRange } = useTimeRangeLocation()
  const { selectedTab, tabLayouts } = useTabs()
  const { dashboardLevel, dashboardLevelLoaded, dealership, website, manufacturer } =
    useAnalyticsDashboard()
  const { setEditingPreviewData, setEditingPreviewTotal } = usePreviewData()

  const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const websiteSlug = useParams<{ websiteSlug: string }>().websiteSlug

  const googleAdsModules = [
    'Google Adwords Ads',
    'Google Display Ads',
    'Google VLA Ads',
    'Google Ads',
  ]
  const provider =
    moduleConfig?.group === 'Facebook Ads'
      ? 'Facebook'
      : googleAdsModules.includes(moduleConfig?.group)
        ? 'Google'
        : null
  const campaignTypeMapping = {
    'Google Adwords Ads': 'SEARCH',
    'Google Display Ads': 'DISPLAY',
    'Google VLA Ads': 'PERFORMANCE_MAX',
  }

  const getAllCampaigns = async (websiteSlug: string): Promise<void> => {
    try {
      setLoading(true)
      const URL = Routes.social_ad_campaigns_dealership_social_ad_accounts_path(
        dealership?.id,
        { provider: provider, website_slug: websiteSlug } // , website_slug: websiteSlug
      )
      const response = await fetch(URL)
      const data = await response.json()
      if (response.ok) {
        const campaigns = data?.campaigns
        if (
          googleAdsModules.includes(moduleConfig?.group) &&
          campaignTypeMapping.hasOwnProperty(moduleConfig?.group)
        ) {
          const filteredCampaigns = campaigns.filter(
            (campaign) => campaign.campaignType === campaignTypeMapping[moduleConfig?.group]
          )
          setAllCampaigns(filteredCampaigns)
        } else {
          setAllCampaigns(campaigns)
        }
      } else console.error('Error:', data)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddIncludedCampaigns = (selectedOptions: { value: Campaign }[]): void => {
    const selectedValues = selectedOptions.map((option) => option.value)
    setIncludedCampaigns(
      selectedValues?.sort((a, b) =>
        `${a.adAccountName}: ${a.name}`.localeCompare(`${b.adAccountName}: ${b.name}`)
      )
    )
    const removedCampaigns = allCampaigns
      .filter((category) => !selectedValues.some((c) => c.externalId === category.externalId))
      .sort((a, b) =>
        `${a.adAccountName}: ${a.name}`.localeCompare(`${b.adAccountName}: ${b.name}`)
      )
    setExcludedCampaigns([...removedCampaigns])
  }

  useEffect(() => {
    getAllCampaigns(websiteSlug)
  }, [])

  useEffect(() => {
    if (allCampaigns.length === 0) return

    const currentModule = tabLayouts[selectedTab]?.find((tab) => tab.module === item.module)
    const newIncludedCampaigns = currentModule?.filters?.adCampaigns || allCampaigns

    setIncludedCampaigns(
      newIncludedCampaigns.filter((c) =>
        allCampaigns.some((campaign) => c.externalId === campaign.externalId)
      )
    )
    setExcludedCampaigns(
      allCampaigns.filter(
        (campaign) => !newIncludedCampaigns.some((c) => c.externalId === campaign.externalId)
      )
    )
  }, [allCampaigns, tabLayouts, selectedTab, item.module])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    fetchModuleData({
      module: item?.module,
      timeRange: timeRange,
      adCampaigns: includedCampaigns,
      selectedTab,
      dashboardLevel,
      dashboardLevelLoaded,
      dealership,
      website,
      manufacturer,
    }).then((data) => {
      setEditingPreviewData((prevState) => {
        return {
          ...prevState,
          [item?.module]: data[item?.module],
        }
      })
    })
    if (provider === 'Facebook' || provider === 'Google') {
      // use hook to get previous time period
      getPreviousTimePeriod(
        item?.module,
        timeRange,
        selectedTab,
        dashboardLevel,
        dashboardLevelLoaded,
        dealership,
        website,
        manufacturer,
        signal,
        includedCampaigns
      ).then((data) => {
        setEditingPreviewTotal((prevState) => {
          return {
            ...prevState,
            [item?.module]: data[item?.module]?.total,
          }
        })
      })
    }
    // return () => controller.abort()
  }, [includedCampaigns, excludedCampaigns])

  const campaignType = googleAdsModules.includes(moduleConfig?.group)
    ? campaignTypeMapping[moduleConfig?.group]
    : null

  const getCampaignTypeDisplayName = (campaignType: string): string => {
    return capitalize(campaignType.split('_').join(' ').toLowerCase())
  }

  return (
    <>
      {!loading ? (
        <div className="w-75">
          {campaignType ? (
            <div className="d-flex pb-3">
              <span>
                <h5 className="mr-3 mb-0">Campaign type:</h5>
              </span>
              <span>
                <h5 className="mb-0">{getCampaignTypeDisplayName(campaignType)}</h5>
              </span>
            </div>
          ) : null}
          <div className="d-flex">
            <span>
              <h5 className="mr-3 mb-0">Filter campaigns:</h5>
            </span>
            <Select
              isMulti
              isClearable={false}
              placeholder="Select Campaign"
              value={includedCampaigns.map((campaign) => ({
                label: `${campaign.adAccountName}: ${campaign.name}`,
                value: campaign,
                type: campaign.campaignType,
              }))}
              options={excludedCampaigns.map((campaign) => ({
                label: `${campaign.adAccountName}: ${campaign.name}`,
                value: campaign,
                type: campaign.campaignType,
              }))}
              className="mr-2"
              onChange={handleAddIncludedCampaigns}
              onKeyDown={(event) => {
                // Prevent clearing the last item using backspace
                if (event.key === 'Backspace' && includedCampaigns.length === 1) {
                  event.preventDefault()
                }
              }}
              components={{
                MultiValueRemove,
                MultiValue,
                Option,
                MultiValueLabel,
              }} // Override the default components
              styles={{
                option: (provided) => ({
                  ...provided,
                  textAlign: 'left', // Aligns options text to the left
                }),
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default FilterCampaigns
