import type { ModuleProps } from '../../../types'
import * as Routes from '../../../../../routes'
import { useViewportData } from '../../../hooks'
import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { useAnalyticsDashboard } from '../../../contexts/hooks'

const LeaderBoard: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { dealership } = useAnalyticsDashboard()

  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  return (
    <div ref={viewportRef}>
      {!loading ? (
        <>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Salesperson</th>
                <th>Delivery Photos</th>
                <th>Reviews</th>
              </tr>
            </thead>
            <tbody>
              {data?.users?.map((user, index) => (
                <tr key={`leaderboard_user_${user.name}_${index}`}>
                  <td>
                    <a href={Routes.dealership_user_path(dealership.slug, user.slug)}>
                      {user.name} ({user.rating})
                    </a>
                  </td>
                  <td>{user.deliveries || 0}</td>
                  <td>{user.reviews || 0}</td>
                </tr>
              ))}
              <tr className="font-weight-bold">
                <td>Total</td>
                <td>{data?.total_deliveries || 0}</td>
                <td>{data?.total_reviews || 0}</td>
              </tr>
            </tbody>
          </table>
          <a
            href={Routes.dealership_contacts_path(dealership.slug)}
            className="btn btn-outline-primary btn-block"
          >
            <i className="fa fa-plus"></i> Delivery
          </a>
        </>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default LeaderBoard
