import React from 'react'
import { CampaignPerformance } from '../../../types'

interface TotalRowProps {
  totalRow: { [key: string]: any }
  columns: { field: string; header: string }[]
}

const TotalRow: React.FC<TotalRowProps> = ({ totalRow, columns }) => {
  return (
    <table className="table table-sm table-striped mt-3" style={{ fontSize: '15px' }}>
      <tbody>
        <tr>
          <td className="text-left font-weight-bold">Total</td>
          {columns.slice(1).map((col, idx) => (
            <td key={`total_row_${idx}`} className="text-center">
              <b>{totalRow[col.field]}</b>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default TotalRow
