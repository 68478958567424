import { CSSProperties, useContext, useEffect, useState } from 'react'
import DragHandle from '../../DragAndDrop/components/DragHandle'
import ModuleCloser from '../../EditMode/components/ModuleCloser'
import type { ModuleComponentConfig, ModuleItem, TimeRange, User } from '../../../types'
import { getModuleComponentFromName } from '../../../utils'
import { Provider, Root, Trigger, Content } from '@radix-ui/react-tooltip'
import EditModule from '../../EditModule/components/EditModule'
import * as Routes from '../../../../../routes'
import { overrideExcludedModules, salesCategories } from '../../../constants'
import {
  ExcludedCategoriesDisclaimer,
  InsufficientDataSourcesDisclaimer,
  SpamLeadsDisclaimer,
} from '../../EditModule/components/Disclaimers'
import { CurrentUserContext } from '../../../../contexts'
import { useAnalyticsDashboard, useConfig, useItems, useTabs } from '../../../contexts/hooks'
import { useValidExpandPositions } from './hooks'

type ModuleWrapperComponentProps = {
  title: string
  item: ModuleItem
  itemIndex: number
  timeRange?: TimeRange
  children: React.ReactNode
  isDragging?: boolean
  draghandleProps?: any
  showTimeFrame?: boolean
  button?: ModuleComponentConfig['button']
  Icon?: React.FC
  toggleExpandToRowWidth?: () => void
  expandToRowWidth?: boolean
}

export const TooltipContentStyles: CSSProperties = {
  borderRadius: '4px',
  padding: '10px 15px',
  fontSize: '15px',
  lineHeight: '1.2',
  color: 'white',
  backgroundColor: 'rgb(0, 66, 122,0.9)',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: '99',
  maxWidth: '400px',
}

const TitleWithIcon: React.FC<{
  titleToDisplay: string
  editModeEnabled: boolean
  moduleConfig?: ModuleComponentConfig
  Icon?: React.FC
}> = ({ titleToDisplay, Icon, editModeEnabled, moduleConfig }) => (
  <div className="d-flex align-items-center">
    {Icon ? <Icon /> : null}
    <h4 className="mx-0 my-1" style={{ fontSize: editModeEnabled ? '14px' : '21px' }}>
      {titleToDisplay}
    </h4>
    <Provider delayDuration={50}>
      <Root>
        <Trigger asChild>
          <i className="fas fa-info-circle ml-2" style={{ cursor: 'pointer' }}></i>
        </Trigger>
        <Content style={TooltipContentStyles} sideOffset={5}>
          {moduleConfig?.infoDescription}
        </Content>
      </Root>
    </Provider>
  </div>
)
const ModuleWrapperComponent: React.FC<ModuleWrapperComponentProps> = ({
  title,
  item,
  itemIndex,
  timeRange,
  children,
  isDragging,
  draghandleProps,
  showTimeFrame = true,
  button,
  Icon,
  expandToRowWidth,
  toggleExpandToRowWidth,
}) => {
  const currentUser = useContext(CurrentUserContext) as User

  const { selectedTab } = useTabs()
  const { config } = useConfig()
  const { editModeEnabled, dealership } = useAnalyticsDashboard()
  const { chartTypes } = useItems()
  const [excludedCategoriesToDisplay, setExcludedCategoriesToDisplay] = useState<string[]>([])

  const moduleConfig = getModuleComponentFromName(item.module)

  const chartTypeExists = chartTypes[selectedTab] ? item?.module in chartTypes[selectedTab] : false
  const defaultChartType = moduleConfig?.defaultChartType || 'Bar'
  const chartType = chartTypeExists ? chartTypes[selectedTab][item.module] : defaultChartType

  const windowSize = window.innerWidth

  const {
    validSmallLayoutExpandPositions,
    validMediumLayoutExpandPositions,
    validLargeLayoutExpandPositions,
  } = useValidExpandPositions(itemIndex)

  const showExpandButton =
    currentUser?.admin &&
    !editModeEnabled &&
    windowSize > 1200 &&
    (validSmallLayoutExpandPositions ||
      validMediumLayoutExpandPositions ||
      validLargeLayoutExpandPositions)

  const titleToDisplay = item?.filters?.title || title

  // Disclaimer configurations
  const showSpamLeadsDisclaimerFilter =
    typeof item?.filters?.showSpamLeadsDisclaimer === 'undefined'
      ? true
      : item?.filters?.showSpamLeadsDisclaimer
  const showInsufficientDataSourcesDisclaimerFilter =
    typeof item?.filters?.showInsufficientDataSourcesDisclaimer === 'undefined'
      ? true
      : item?.filters?.showInsufficientDataSourcesDisclaimer
  const showExcludedCategoriesDisclaimerFilter =
    typeof item?.filters?.showExcludedCategoriesDisclaimer === 'undefined'
      ? true
      : item?.filters?.showExcludedCategoriesDisclaimer

  const getAllCategories = async (): Promise<void> => {
    if (dealership) {
      try {
        const URL = Routes.categories_dealership_lead_clusters_path(dealership?.id)
        const response = await fetch(URL)
        const data = await response.json()
        if (response.ok) {
          setExcludedCategoriesToDisplay(() => {
            if (item?.filters?.leadConversionCategories) {
              return data.filter(
                (category: string) => !item?.filters?.leadConversionCategories?.includes(category)
              )
            } else {
              return data.filter((category: string) => !salesCategories.includes(category))
            }
          })
        } else console.error('Error:', data)
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  useEffect(() => {
    if (moduleConfig?.isConversionModule || moduleConfig?.isConversionRateModule) getAllCategories()
  }, [item, dealership])

  useEffect(() => {
    if (expandToRowWidth) toggleExpandToRowWidth()
  }, [selectedTab])

  const safeTimeRangeOverride = config?.defaultTimeRangeOverride ?? []

  const showGraphOverride =
    safeTimeRangeOverride.includes(moduleConfig?.group) &&
    !overrideExcludedModules.includes(item?.module)

  return (
    <>
      <div className="align-items-center">
        <div
          className={`col-12 d-flex px-0 align-items-center ${
            editModeEnabled ? 'justify-content-end' : 'justify-content-between'
          }`}
        >
          {!editModeEnabled ? (
            <TitleWithIcon
              titleToDisplay={titleToDisplay}
              Icon={Icon}
              editModeEnabled={editModeEnabled}
              moduleConfig={moduleConfig}
            />
          ) : null}
          {showTimeFrame && !editModeEnabled && (
            <span className="small text-secondary m-0">
              {timeRange?.timeframe || 'Last 30 Days'}
            </span>
          )}
          {button && !editModeEnabled && (
            <span>
              <a href={button.link(dealership)} className="btn btn-secondary btn-sm">
                {button.text}
              </a>
            </span>
          )}
          {editModeEnabled ? (
            <>
              <EditModule item={item} chartType={chartType} />
              <DragHandle isDragging={isDragging} draghandleProps={draghandleProps} />
              <ModuleCloser item={item} />
            </>
          ) : null}
        </div>
        {!editModeEnabled && showGraphOverride ? (
          <div className="col-12 d-flex justify-content-end pr-0">
            <small className="float-right text-secondary">*Graph overridden to yearly</small>
          </div>
        ) : null}
        <div className="mr-auto d-flex align-items-center ">
          {editModeEnabled ? (
            <TitleWithIcon
              titleToDisplay={titleToDisplay}
              Icon={Icon}
              editModeEnabled={editModeEnabled}
              moduleConfig={moduleConfig}
            />
          ) : null}
          {showExpandButton ? (
            <div className="ml-auto">
              <i
                className="fa-solid fa-arrows-left-right ml-1 "
                style={{ cursor: 'pointer' }}
                onClick={() => toggleExpandToRowWidth()}
              ></i>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={
          editModeEnabled ? { overflow: 'auto', height: 'calc(100% - 25px)' } : { height: '100%' }
        }
      >
        {children}
        {moduleConfig?.showSpamLeadsDisclaimer && showSpamLeadsDisclaimerFilter ? (
          <SpamLeadsDisclaimer />
        ) : null}
        {moduleConfig?.showExcludedCategoriesDisclaimer &&
        showExcludedCategoriesDisclaimerFilter &&
        excludedCategoriesToDisplay.length > 0 ? (
          <ExcludedCategoriesDisclaimer excludedCategories={excludedCategoriesToDisplay} />
        ) : null}
        {moduleConfig?.showInsufficientDataSourcesDisclaimer &&
        showInsufficientDataSourcesDisclaimerFilter ? (
          <InsufficientDataSourcesDisclaimer />
        ) : null}
      </div>
    </>
  )
}

export default ModuleWrapperComponent
