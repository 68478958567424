import { ModuleComponentConfig } from '../../../types'
import { getColor } from '../../../utils'

const PreviousTimePeriod: React.FC<{
  previousPeriod: number
  lastYear: number
  moduleConfig: ModuleComponentConfig
}> = ({ previousPeriod, lastYear, moduleConfig }) => {
  const shouldReverse =
    moduleConfig.title === 'Cost Per Click' || moduleConfig.title === 'Cost Per Lead'
  return (
    <div className="row">
      <div className="col-6">
        <small className="text-secondary">Previous year</small>
        <p className="mb-0">
          <b className={getColor(lastYear, shouldReverse)}>{lastYear ? lastYear + '%' : 'N/A'}</b>
        </p>
      </div>
      <div className="col-6 d-flex justify-content-end flex-column align-items-end">
        <small className="text-secondary">Previous period</small>
        <p className="mb-0">
          <b className={getColor(previousPeriod, shouldReverse)}>
            {previousPeriod ? previousPeriod + '%' : 'N/A'}
          </b>
        </p>
      </div>
    </div>
  )
}

export default PreviousTimePeriod
